import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { setAppGlobals, setSpinner, useLoyaltyPoints, setMyRewardsPointsIndicator } from '../../Redux/ActionCreator';
import appointmentObject from '../../shared/Utils/POST/AppointmentObject';
import SharedCheckBox from '../../shared/Checkbox/SharedCheckBox';
const constants = require('../../shared/Constants/SharedConstants');
const flowConstants = require('../../shared/RoutingFlow/FlowComponentsConstants');
import {setBrandText} from '../MyRewards/MyRewards';

class SelectedServices extends Component {
    changeCheckBox(e) {
        this.props.useLoyaltyPoints(e.target.checked);
        if (e.target.checked) {
            this.props.setMyRewardsPointsIndicator(constants.yes);
        } else if (!e.target.checked) {
            this.props.setMyRewardsPointsIndicator(constants.no);
        }
    }
    getLineItems() {
        if (this.props.vLineItems) {
            const lineItemServices = [];
            for (let i = 0; i < this.props.vLineItems.lineItems.length; i++) {
                lineItemServices[i] = new LineItem(
                    this.props.vLineItems.lineItems[i].code,
                    this.props.vLineItems.lineItems[i].couponCode,
                    this.props.vLineItems.lineItems[i].description,
                    this.props.vLineItems.lineItems[i].name,
                    this.props.vLineItems.lineItems[i].price,
                    this.props.vLineItems.lineItems[i].secondaryPrice,
                    this.props.vLineItems.lineItems[i].parentServiceName
                );

                if (lineItemServices[i].price === undefined) {
                    lineItemServices[i].price = '';
                }

                if (lineItemServices[i].secondaryPrice === undefined) {
                    lineItemServices[i].secondaryPrice = '';
                }
            }

            lineItemServices.sort((a, b) => {
                const textA = a.name.toLowerCase();
                const textB = b.name.toLowerCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
            });

            return lineItemServices;
        }
    }

    renderNTFLineItems() {
        let lineItems;
        if (this.props.vLineItems.lineItems && this.props.vLineItems.lineItems.length > 0) {
            lineItems = this.getLineItems();
        } else {
            lineItems = appointmentObject(true).lineItems;
        }

        return lineItems.map((row) => {
            const { code, name, price, parentServiceName } = row;
            // Hide service note converted to line item
            if (name !== this.props.vLineItems.serviceNotes) {
                return (
                    <div key={code} className="service-item">
                        <div className="service-title">
                            {parentServiceName ||
                                name
                                    .replace('Recall', this.props.vTranslations.ADDTLSERVICES_RECALL)
                                    .replace('Maintenance' + ' Schedule', this.props.vTranslations.MAINTENANCE_SCHED)
                                    .replace('Miles', this.props.vTranslations.MILES)}
                        </div>
                        <div className="service-offer">{parentServiceName ? name : null}</div>
                        <div className="service-price bold">{price}</div>
                    </div>
                );
            }
        });
    }

    renderTFItems() {
        if (this.props.vTires) {
            return this.props.vTires.map((tire) => (
                <div className="service-item">
                    <div className="service-item-row">
                        <div className="service-item-cell title-container">
                            <div className="non-recall-items bold">{tire.name}</div>
                            <div>{tire.description}</div>
                        </div>
                    </div>
                </div>
            ));
        }
    }

    renderLineItems() {
        const div = (
            <>
                <div>{this.renderNTFLineItems()}</div>
                <div>{this.renderTFItems()}</div>
            </>
        );
        return div;
    }

    getOssGlobals() {
        if (this.props.vVin && this.props.selectedDealer.bac && this.props.vMiles && this.props.vCountry) {
            this.props.setSpinner(true);
            const url =
                `${process.env.REACT_APP_OSS_API_BASE_URL}/oss/oss/api/v4.0/US/pega/services?` +
                `bac=${this.props.selectedDealer.bac}&vin=${this.props.vVin}&mileage=${this.props.vMiles}&units=true&co=${this.props.vCountry}&lang=en-US&format=json`;
            axios.get(url).then((res) => {
                if (Object.keys(res.data).length > 0) {
                    this.props.setAppGlobals({
                        data: res.data,
                        bac: this.props.selectedDealer.bac,
                        country: this.props.vCountry,
                    });
                    this.props.setSpinner(false);
                }
            });
        }
    }

    render() {
        let { vTranslations, preferredBrand, themeMake, make, useMyRewardsIndicator, usePoints, brands } = this.props;
        const brand = setBrandText(vTranslations, preferredBrand, themeMake, make, brands);
        if (useMyRewardsIndicator === constants.yes && !usePoints) {
            this.props.useLoyaltyPoints(true);
        }
        return (
            <div className="review-confirm-services-info paper">
                <div className="review-confirm-services-content">
                    <div className="review-confirm-services-title-container">
                        <div className="review-confirm-services-title headline3">
                            {this.props.vTranslations.SELECTED_SERVICES}
                        </div>
                        {this.props.headerNav !== constants.CONFIRMATION && this.props.modifyFlag !== true && this.props.dmsError !== true ? (
                            <div className="review-confirm-services-edit hide-on-mobile">
                                <Link
                                    id="btnServiceEdit"
                                    className="services-edit-link"
                                    to={{ pathname: '/OssLandingPage' }}
                                    onClick={() => {
                                        this.props.selectedDealer.bac !== this.props.vprevBac
                                            ? this.getOssGlobals()
                                            : null;
                                    }}
                                >
                                    {this.props.vFlowSource !== flowConstants.EditAppointmentFlow
                                        ? this.props.vTranslations.EDIT
                                        : null}
                                </Link>
                            </div>
                        ) : null}
                    </div>
                    <div className="review-confirm-services-list">
                        {this.renderLineItems()}
                        <div className="review-confirm-services-comments service-item bold">
                            {this.props.vFlowSource !== flowConstants.EditAppointmentFlow
                                ? this.props.vMiscService
                                : this.props.vLineItems.serviceNotes}
                        </div>
                    </div>
                    {this.props.headerNav !== constants.CONFIRMATION && this.props.modifyFlag !== true && this.props.dmsError !== true ?  (
                        <div className="review-confirm-services-edit hide-on-desktop">
                            <Link
                                className="services-edit-link"
                                data-dtm="appointment details:vehicle information"
                                to={{ pathname: '/OssLandingPage' }}
                                onClick={() => {
                                    this.props.selectedDealer.bac !== this.props.vprevBac ? this.getOssGlobals() : null;
                                }}
                            >
                                {this.props.vTranslations.EDIT}
                            </Link>
                        </div>
                    ) : null}
                </div>
                {/*Show the 'Use Points' check box to the user when:
                    - Dealer is 'RedeemEligible'
                    - Users Loyalty account is 'active'
                    - Only show check box on confirmation page if user checked 'usePoints'
                   OR when:
                    - flowSource is 'EditAppointment'
                    - useMyRewardsIndicator isn't 'notApplicable'
                    - Users Loyalty account is 'active'*/}
                {(this.props.vRedeemEligible === true &&
                    this.props.active === true &&
                    this.props.getLoyalty === 'true' &&
                    ((this.props.headerNav === constants.CONFIRMATION && this.props.usePoints) ||
                        this.props.headerNav !== constants.CONFIRMATION)) ||
                (this.props.vFlowSource === flowConstants.EditAppointmentFlow &&
                    useMyRewardsIndicator !== constants.notApplicable) ? ( // &&
                    //this.props.active === true)
                    <div
                        className={
                            'check-box-wrapper' +
                            (this.props.usePoints ? ' gray' : '') +
                            ' ' +
                            this.props.tierName.toLowerCase()
                        }
                    >
                        <SharedCheckBox
                            readOnly={
                                (this.props.usePoints ||
                                    this.props.useMyRewardsIndicator !== constants.notApplicable) &&
                                this.props.headerNav === constants.CONFIRMATION
                            }
                            checked={this.props.usePoints}
                            onChange={(e) => this.changeCheckBox(e)}
                        />
                        <div className="check-box-text">
                            {this.props.vTranslations.USE_MY_REWARD_POINTS_PART_ONE} {brand}
                            {this.props.vTranslations.USE_MY_REWARD_POINTS_PART_TWO}
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    brands: state.prefillReducer.dealerSummaryObject.dealershipSummary.brands,
    headerNav: state.headReducer.headerNavigation,
    headerTitle: state.headReducer.headerTitle,
    modifyFlag: state.appointmentReducer.modifyFlag,
    selectedDealer: state.locReducer.selectedDealer,
    vCountry: state.prefillReducer.country,
    vFlowSource: state.prefillReducer.flowSource,
    vLineItems: state.prefillReducer.editAppointmentObject,
    vMiles: state.prefillReducer.miles,
    vMiscService: state.ossAppGlobals.miscService,
    vprevBac: state.ossAppGlobals.bac,
    vPriceDisclaimer: state.locReducer.priceDisclaimer,
    vSelectedDealer: state.locReducer.selectedDealer,
    vServices: state.ossAppGlobals.servicesArrayList,
    vTires: state.ossAppGlobals.tires,
    vTranslations: state.countryOptionsReducer.translations,
    vVin: state.prefillReducer.vin,
    usePoints: state.myRewardsReducer.usePoints,
    vRedeemEligible: state.myRewardsReducer.redeemEligible,
    tierName: state.myRewardsReducer.tierName,
    active: state.myRewardsReducer.active,
    themeMake: state.prefillReducer.themeMake,
    make: state.prefillReducer.make,
    preferredBrand: state.myRewardsReducer.preferredBrand,
    getLoyalty: state.countryOptionsReducer.countryOptions.GET_LOYALTY,
    useMyRewardsIndicator: state.prefillReducer.useMyRewardsIndicator,
    dmsError: state.appointmentReducer.dmsError,
});

function LineItem(code, couponCode, description, name, price, secondaryPrice, parentServiceName) {
    this.code = code;
    this.couponCode = couponCode;
    this.description = description;
    this.name = name;
    this.price = price;
    this.secondaryPrice = secondaryPrice;
    this.parentServiceName = parentServiceName;
}

export default connect(mapStateToProps, {
    setAppGlobals,
    setSpinner,
    useLoyaltyPoints,
    setMyRewardsPointsIndicator,
})(SelectedServices);
withRouter(SelectedServices);
