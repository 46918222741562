import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { batch, connect } from 'react-redux';
import dayjs from 'dayjs';
import {
    resetPreviousBac,
    setDeleteAppointmentFlag,
    setFlowSource,
    setModifyFlag,
    setNewAppointmentFlag,
    setPrefillHeader,
    setVehicleData,
    setHideContactInfoFlag,
    setValidFlag,
    setMobileServiceNotAvailable,
    setMobileServiceNextPage,
    setPickUpAddressError,
} from '../../Redux/ActionCreator';
import CancelAppointmentPopup from '../Confirmation/CancelAppointmentPopup';
import {
    editAppointmentAxios,
    getAppGlobals,
    getCalendarInvite,
    getCountryOptions,
    getDealersByRegion,
    getSummaryBAC,
    getVehicleMakes,
    getVehicleModels,
    getVehiclePlus,
    setModifyEmpty,
    submitAppointment,
    searchByLicensePlateViewApiCall,
} from '../../shared/BackendCalls/SharedBackendGets';
import findNextFlow from '../../shared/RoutingFlow/RoutingFlow';
import appointmentObject from '../../shared/Utils/POST/AppointmentObject';
import { resetPageNameForAnalytics } from '../../shared/Utils/analytics.service';
import LDContextUpdate from '../App/LDContextUpdate';
import {
    CADILLAC_PREMIUM_CONCIERGE,
    MOBILE_SERVICE,
    PREMIUM_CONCIERGE,
    PREMIUM_CONCIERGE_WITH_LOANER,
} from '../Appointment/AppointmentConstants';
import store from '../../Redux/Store';
import ShowAllLDFlag from '../Locator/ShowAllLDFlag';

const advancedFormat = require('dayjs/plugin/advancedFormat');
const customParseFormat = require('dayjs/plugin/customParseFormat');
const constants = require('../../shared/Constants/SharedConstants');
const flowConstants = require('../../shared/RoutingFlow/FlowComponentsConstants');

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCancelAppointmentPopup: false,
            pegaCount: 0,
        };
    }

    handleLocatorPage(props) {
        // Check flow source - process accordingly
        if (props.flowSource === 'Authenticated' || props.flowSource === 'AuthenticatedOCPlus') {
            // Initialize locator page
            if (props.dealerships.length === 0) {
                getDealersByRegion();
            }
        }
        this.props.vPreviousBac !== this.props.vprevBac
            ? this.props.resetPreviousBac({ bac: this.props.vprevBac })
            : '';
    }

    getOssGlobals(bac) {
        const apptObject = appointmentObject();

        if (bac && this.props.vCountry) {
            if (
                this.props.headerTitle ===
                (this.props.vTranslations.SELECT_VEHICLE || this.props.vTranslations.CONFIRMATION)
            ) {
                getAppGlobals(
                    this.props.vCountry,
                    this.props.searchByLicensePlateView ? this.props.searchByLicensePlate_selectedVin : this.props.vVin,
                    this.props.newAppointmentFlag ? apptObject.bac : bac,
                    this.props.vehicleYear,
                    this.props.vehicleMake,
                    this.props.vehicleModel,
                    this.props.vLocale,
                    '',
                    this.props.vPlus,
                    this.props.vehicleMiles,
                    this.props.vUnits,
                    this.props.record
                );
            } else if (
                this.props.searchByLicensePlateView === true &&
                this.props.searchByLicensePlateRespReceived === true
            ) {
                getAppGlobals(
                    this.props.vCountry,
                    this.props.searchByLicensePlate_selectedVin,
                    this.props.newAppointmentFlag ? apptObject.bac : bac,
                    this.props.searchByLicensePlate_selectedYear,
                    this.props.searchByLicensePlate_selectedMake,
                    this.props.searchByLicensePlate_selectedModel,
                    this.props.vLocale,
                    '',
                    this.props.vPlus,
                    this.props.vehicleMiles,
                    this.props.vUnits,
                    this.props.record
                );
            } else {
                getAppGlobals(
                    this.props.vCountry,
                    this.props.vVin,
                    bac,
                    this.props.vYear || this.props.vehicleYear,
                    this.props.vMake || this.props.vehicleMake,
                    this.props.vModel || this.props.vehicleModel,
                    this.props.vLocale,
                    this.props.vprevBac,
                    this.props.vPlus,
                    this.props.vMiles,
                    this.props.vUnits,
                    this.props.record
                );
            }
        }
    }

    /*
     * Objective: After a user has selected a state and license plate,
     * call backend api with this information
     */
    getSearchbyLicensePlate(state, licensePlate) {
        searchByLicensePlateViewApiCall(state, licensePlate);
    }

    reloadPage = () => {
        this.props.setModifyFlag('false');
        this.props.setNewAppointmentFlag(true);
        this.props.setHideContactInfoFlag('false');
    };

    submitAppointment = () => {
        submitAppointment();
    };

    ics = () => {
        const apptObject = appointmentObject();

        getCalendarInvite(this.props.vCountry, apptObject.bac, this.props.record);
    };

    newAppointment() {
        this.props.setDeleteAppointmentFlag(false);
        const apptObject = appointmentObject();
        this.props.setPrefillHeader(this.props.vPrefill);
        getCountryOptions(this.props.vCountry, this.props.vLocale, this.props.flowSource);

        if (this.props.flowSource === 'Authenticated' || this.props.flowSource === 'AuthenticatedOCPlus') {
            this.getOssGlobals(apptObject.bac);
            this.props.history.push({
                pathname: this.props.vTranslations[findNextFlow(this.props.flowSource, 'EntryParam', 'Forward')],
            });
        } else {
            this.props.setFlowSource('Dealer');
            this.props.setVehicleData({
                key: 'selectedYear',
                value: apptObject.vehicle.vehicle.year,
            });
            this.props.setVehicleData({
                key: 'selectedMake',
                value: apptObject.vehicle.vehicle.make.make,
            });
            this.props.setVehicleData({
                key: 'selectedModel',
                value: apptObject.vehicle.vehicle.model.model,
            });
            this.props.setVehicleData({
                key: 'selectedMileage',
                value: apptObject.vehicle.mileage,
            });
            this.props.setVehicleData({
                key: 'selectedLicense',
                value: apptObject.vehicle.licensePlate,
            });
            this.props.setVehicleData({
                key: 'selectedMotor',
                value: apptObject.vehicle.vehicle.plus.plus,
            });
            this.props.setVehicleData({
                key: 'selectedRegistration',
                value: apptObject.vehicle.registrationId,
            });
            getVehicleMakes(this.props.vCountry, apptObject.vehicle.vehicle.year);
            getVehicleModels(
                this.props.vCountry,
                apptObject.vehicle.vehicle.year,
                apptObject.vehicle.vehicle.make.make
            );
            getVehiclePlus(
                this.props.vCountry,
                apptObject.vehicle.vehicle.year,
                apptObject.vehicle.vehicle.make.make,
                apptObject.vehicle.vehicle.model.model
            );
            this.props.history.push({
                pathname: this.props.vTranslations[findNextFlow('Dealer', 'EntryParam', 'Forward')],
            });
        }
    }

    toggleCancelAppointmentPopup() {
        this.setState({
            showCancelAppointmentPopup: !this.state.showCancelAppointmentPopup,
        });
        window.scrollTo(0, 0);
    }

    cancelModifyAppointment() {
        resetPageNameForAnalytics('analyticsPageName');
        editAppointmentAxios();
    }

    isMyBrandOrigin() {
        const origin = this.props.ultimateOrigin;
        let isMyBrand;
        const MY_BRAND = 'OCAPP';

        if (origin && origin.indexOf(MY_BRAND) > -1) {
            isMyBrand = true;
        } else {
            isMyBrand = false;
        }

        return isMyBrand;
    }

    // Function for assigning footer button styles
    footerButtonStyles() {
        let linkClasses = 'main-button button primary-button';
        const disabled = ' disabled';

        if (this.props.headerNavigation === constants.TITLE_SERVICES) {
            if (
                this.props.vselectedCount <= 0 ||
                this.props.showSpinner === true ||
                (this.props.countryOptions.EV_DEALER_LOCATOR === 'true' &&
                    !this.props.vEvCertified &&
                    this.props.flowSource !== flowConstants.AuthenticatedFlow)
            ) {
                linkClasses += disabled;
            }
        } else if (this.props.headerNavigation === constants.DEALERSHIP_LOC) {
            if (
                this.props.selectedDealer &&
                this.props.selectedDealer.name &&
                // Added conditional to check for undefined in cases where dealers have children dealerships
                // and locator page is displayed without geoCoordinates
                // ex: bac 224926 NZ Dealer flow
                (this.props.selectedDealer?.serviceCertifiedResponse?.certified ||
                    this.props.selectedDealer.serviceCertifiedResponse === undefined)
            ) {
                if (this.props.selectedDealer.bac !== this.props.dealerSummary.dealershipSummary.bac) {
                    getSummaryBAC(this.props.vCountry, this.props.selectedDealer.bac);
                }
            } else {
                linkClasses += disabled;
            }
        } else if (this.props.headerNavigation === constants.APPOINTMENT) {
            if (
                !this.props.selectedDate ||
                (this.props.selectedDate && !this.props.validFlag) ||
                this.props.loadingFlag
            ) {
                linkClasses += disabled;
            }
        } else if (this.props.headerNavigation === constants.CONFIRMATION) {
            linkClasses = 'button secondary-button';
        } else if (this.props.headerNavigation === constants.SELECT_VEHICLE) {
            if (this.props.validFlag !== true) {
                linkClasses += disabled;
            }
        }
        return linkClasses;
    }

    // Function for footer button on appointment page
    appointmentPageFooterButton() {
        let footerButton;

        if (this.props.modifyFlag === true) {
            footerButton = (
                <button
                    id="btnContinue"
                    className={this.footerButtonStyles()}
                    onClick={(e) => {
                        if (
                            (this.props.transportation?.code === PREMIUM_CONCIERGE ||
                                this.props.transportation?.code === MOBILE_SERVICE ||
                                this.props.transportation?.code === PREMIUM_CONCIERGE_WITH_LOANER ||
                                this.props.transportation?.code === CADILLAC_PREMIUM_CONCIERGE) &&
                            !this.props.pickupAddress
                        ) {
                            window.scrollTo(0, 0);
                            store.dispatch(setPickUpAddressError('true'));
                        } else {
                            this.props.history.push({ pathname: constants.REVIEW_LINK });
                        }
                        if (this.footerButtonStyles().includes('disabled')) {
                            e.preventDefault();
                        }
                    }}
                >
                    {this.props.footerButtonText}
                </button>
            );
        } else {
            footerButton = (
                <button
                    id="btnContinue"
                    className={this.footerButtonStyles()}
                    onClick={(e) => {
                        if (
                            (this.props.transportation?.code === PREMIUM_CONCIERGE ||
                                this.props.transportation?.code === MOBILE_SERVICE ||
                                this.props.transportation?.code === PREMIUM_CONCIERGE_WITH_LOANER ||
                                this.props.transportation?.code === CADILLAC_PREMIUM_CONCIERGE) &&
                            !this.props.pickupAddress
                        ) {
                            window.scrollTo(0, 0);
                            store.dispatch(setPickUpAddressError('true'));
                        } else {
                            this.props.history.push({ pathname: this.props.footerButtonLink });
                        }
                        if (this.footerButtonStyles().includes('disabled')) {
                            e.preventDefault();
                        }
                    }}
                >
                    {this.props.footerButtonText}
                </button>
            );
        }
        return footerButton;
    }

    // Function for footer button on confirmation page
    confirmationPageFooterButton() {
        let footerButton;
        if (this.props.flowSource === flowConstants.EditAppointmentFlow && this.props.cancelled === 'CANCELLED') {
            if (!this.props.recordLocator) {
                this.props.setDeleteAppointmentFlag(true);
            } else {
                this.props.setDeleteAppointmentFlag(false);
            }
        }
        if (this.props.deleteAppointmentFlag === false) {
            let showCalendarButton = false;
            // If any one of these conditions returns true, disable the Add to Calendar button
            if (
                this.isMyBrandOrigin() ||
                this.props.countryOptions.ADD_TO_CALENDAR_MOBILE_APP !== 'false' ||
                this.props.dealerSummary.displayCalendarButton
            ) {
                showCalendarButton = true;
            }

            footerButton = showCalendarButton ? (
                <>
                    <CancelAppointmentPopup
                        closeCancelAppointmentPopup={this.toggleCancelAppointmentPopup.bind(this)}
                        showCancelAppointmentPopup={this.state.showCancelAppointmentPopup}
                    />
                </>
            ) : null;
        } else if (this.props.newAppointmentFlag) {
            footerButton = <Link onClick={this.newAppointment()} className={this.footerButtonStyles()} />;
        } else {
            footerButton = null;
        }
        return footerButton;
    }

    // Function for footer button on dealership locator page
    dealershipLocatorPageFooterButton() {
        let footerButton;

        if (this.props.selectedDealer && this.props.selectedDealer.bac !== this.props.vprevBac) {
            footerButton = (
                <button
                    id="btnContinueLocatorPage"
                    onClick={(e) => {
                        if (this.footerButtonStyles().includes('disabled')) {
                            e.preventDefault();
                        }
                        if (!this.props.vVin) {
                            if (!this.props.vehicleYear || !this.props.vehicleMake || !this.props.vehicleModel) {
                                this.props.history.push({
                                    pathname: '/Vehicle',
                                });
                                this.props.setPrefillHeader(this.props.vPrefill);
                            } else {
                                this.getOssGlobals(this.props.selectedDealer.bac);

                                this.props.history.push({
                                    pathname: '/OssLandingPage',
                                });
                            }
                        } else {
                            // For Mobile Service , pop the message if dealer doesn't offer the mobile service
                            if (
                                this.props.isVinEligibleForMobileService &&
                                !this.props.selectedDealer.mobileServicePlusEnabled
                            ) {
                                batch(() => {
                                    this.getOssGlobals(this.props.selectedDealer.bac);
                                    store.dispatch(setMobileServiceNotAvailable(true));
                                    store.dispatch(setMobileServiceNextPage('/OssLandingPage'));
                                });
                            } else {
                                this.getOssGlobals(this.props.selectedDealer.bac);

                                this.props.history.push({
                                    pathname: '/OssLandingPage',
                                });
                            }
                        }
                    }}
                    className={`${this.footerButtonStyles()} ` + 'stat-button-link'}
                    data-dtm="select a dealer"
                >
                    {this.props.footerButtonText}
                </button>
            );
        } else {
            footerButton = (
                <button
                    id="btnContinueLocatorPage"
                    onClick={(e) => {
                        // For Mobile Service , pop the message if dealer doesn't offer the mobile service
                        if (
                            this.props.isVinEligibleForMobileService &&
                            !this.props.selectedDealer.mobileServicePlusEnabled
                        ) {
                            batch(() => {
                                store.dispatch(setMobileServiceNotAvailable(true));
                                store.dispatch(setMobileServiceNextPage('/Appointment'));
                            });
                        } else {
                            if (this.footerButtonStyles().includes('disabled')) {
                                e.preventDefault();
                            }
                            this.props.history.push({
                                pathname: '/Appointment',
                            });
                        }
                    }}
                    className={`${this.footerButtonStyles()} ` + 'stat-button-link'}
                    data-dtm="select a dealer"
                >
                    {this.props.footerButtonText}
                </button>
            );
        }
        return footerButton;
    }

    // Function for footer button on review page
    reviewPageFooterButton() {
        let footerButton;
        if (this.props.modifyFlag === true) {
            footerButton = (
                <Link
                    id="btnSubmit"
                    to={{ pathname: constants.CONFIRMATION_LINK }}
                    onClick={(e) => {
                        if (this.footerButtonStyles().includes('disabled')) {
                            e.preventDefault();
                        }
                        setModifyEmpty();
                    }}
                    className={this.footerButtonStyles()}
                    data-dtm="confirm appointment"
                >
                    {this.props.footerButtonText}
                </Link>
            );
        } else {
            footerButton = (
                <Link
                    id="btnSubmit"
                    to={this.props.footerButtonLink}
                    onClick={(e) => {
                        if (this.footerButtonStyles().includes('disabled')) {
                            e.preventDefault();
                        }
                        this.submitAppointment();
                    }}
                    data-dtm="confirm appointment"
                    className={this.footerButtonStyles()}
                >
                    {this.props.footerButtonText}
                </Link>
            );
        }

        return footerButton;
    }

    // Function for footer button on services page
    servicesPageFooterButton() {
        let footerButton;
        if (this.props.vPreviousBac && this.props.vPreviousBac !== this.props.vprevBac) {
            footerButton = (
                <Link
                    id="btnContinue"
                    to={this.props.vTranslations.APPOINTMENT_LINK}
                    onClick={(e) => {
                        if (this.footerButtonStyles().includes('disabled')) {
                            e.preventDefault();
                        }
                        this.handleLocatorPage(this.props);
                    }}
                    className={`stat-button-link ${this.footerButtonStyles()}`}
                    data-dtm="service"
                >
                    {this.props.footerButtonText}
                </Link>
            );
        } else {
            footerButton = (
                <Link
                    id="btnContinue"
                    to={this.props.footerButtonLink}
                    onClick={(e) => {
                        if (this.footerButtonStyles().includes('disabled')) {
                            e.preventDefault();
                        }
                        this.handleLocatorPage(this.props);
                    }}
                    className={`stat-button-link ${this.footerButtonStyles()}`}
                    data-dtm="service"
                >
                    {this.props.footerButtonText}
                </Link>
            );
        }
        return footerButton;
    }

    // Function for footer button on vehicle page
    vehiclePageFooterButton() {
        let footerButton;
        if (this.state.pegaCount === 0) {
            this.setState({ pegaCount: 1 });
        }

        if (
            this.props.searchByLicensePlateView &&
            this.props.countryOptions.USE_PLATE_TO_VIN === 'true' &&
            this.props.searchByLicensePlateRespReceived === false
        ) {
            footerButton = (
                <Link
                    to="/Vehicle"
                    onClick={(e) => {
                        if (this.footerButtonStyles().includes('disabled')) {
                            e.preventDefault();
                        }
                        this.getSearchbyLicensePlate(
                            this.props.searchByLicensePlateSelectedState,
                            this.props.searchByLicensePlateEnteredValue
                        );
                    }}
                    className={this.footerButtonStyles()}
                >
                    {this.props.vTranslations.SEARCH}
                </Link>
            );
        } else {
            footerButton = (
                <Link
                    to={this.props.footerButtonLink}
                    onClick={(e) => {
                        if (this.footerButtonStyles().includes('disabled')) {
                            e.preventDefault();
                        }
                        const { bac } = this.props.dealerSummary.dealershipSummary;
                        this.getOssGlobals(bac);
                    }}
                    className={`${this.footerButtonStyles()} stat-button-link`}
                    data-dtm="vehicle"
                >
                    {this.props.footerButtonText}
                </Link>
            );
        }

        return footerButton;
    }

    // Function for "Cancel" button on Review and Appointment Pages when
    // editing submitted appointment
    cancelFooterButton() {
        const footerCancelButton = (
            <Link
                to={{ pathname: constants.CONFIRMATION_LINK }}
                className="cancel-button button secondary-button"
                onClick={() => {
                    this.cancelModifyAppointment();
                    this.props.setValidFlag('true');
                }}
            >
                {this.props.vTranslations.CANCEL}
            </Link>
        );
        return footerCancelButton;
    }

    render() {
        let footerButton;
        let footerCancelButton;

        if (this.props.showCancelButtonsFlag === false && this.props.footerButtonText) {
            if (this.props.headerNavigation === constants.APPOINTMENT) {
                footerButton = this.appointmentPageFooterButton();
                if (this.props.modifyFlag === true) {
                    footerCancelButton = this.cancelFooterButton();
                }
            } else if (this.props.headerNavigation === constants.CONFIRMATION) {
                footerButton = this.confirmationPageFooterButton();
            } else if (this.props.headerNavigation === constants.DEALERSHIP_LOC) {
                footerButton = this.dealershipLocatorPageFooterButton();
            } else if (this.props.headerNavigation === constants.REVIEW) {
                footerButton = this.reviewPageFooterButton();
                if (this.props.modifyFlag === true) {
                    footerCancelButton = this.cancelFooterButton();
                }
            } else if (this.props.headerNavigation === constants.SELECT_VEHICLE) {
                footerButton = this.vehiclePageFooterButton();
            } else if (this.props.headerNavigation === constants.TITLE_SERVICES) {
                footerButton = this.servicesPageFooterButton();
            } else {
                footerButton = null;
            }

            const floatButton = {
                position: 'fixed',
                width: '100%',
                left: '0',
                bottom: '0'
            };
            return (
                <>
                    <ShowAllLDFlag>
                        {(removeShowAll) => (
                            <div id="globalFooter" className={`footer${
                                this.props.flowSource === flowConstants.EditAppointmentFlow ? ' edit-flow' : ''
                            }`} style={removeShowAll ? floatButton : {}}>

                                {footerButton}
                                {this.props.modifyFlag === true &&
                                (this.props.headerNavigation === constants.APPOINTMENT ||
                                    this.props.headerNavigation === constants.REVIEW)
                                    ? footerCancelButton
                                    : null}
                            </div>
                        )}
                    </ShowAllLDFlag>
                    <LDContextUpdate />
                </>
            );
        }
        return (
            <>
                <LDContextUpdate />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    cancelled: state.prefillReducer.editAppointmentObject.appointmentStatus
        ? state.prefillReducer.editAppointmentObject.appointmentStatus
        : '',
    countryOptions: state.countryOptionsReducer.countryOptions,
    customerFirstName: state.appointmentReducer.customerFirstName,
    customerLastName: state.appointmentReducer.customerLastName,
    dealerships: state.locReducer.dealerships,
    dealerSummary: state.prefillReducer.dealerSummaryObject,
    deleteAppointmentFlag: state.confirmationReducer.deleteAppointmentFlag,
    flowSource: state.prefillReducer.flowSource,
    headerNavigation: state.headReducer.headerNavigation,
    headerTitle: state.headReducer.headerTitle,
    modifyFlag: state.appointmentReducer.modifyFlag,
    networkErrorFlag: state.ossAppGlobals.networkErrorFlag,
    newAppointmentFlag: state.confirmationReducer.newAppointmentFlag,
    record: state.reviewReducer.recordLocator
        ? state.reviewReducer.recordLocator
        : state.prefillReducer.editAppointmentRecordLocator,
    footerButtonLink: state.headReducer.footerButtonLink,
    footerButtonText: state.headReducer.footerButtonText,
    recordLocator: state.reviewReducer.recordLocator,
    searchByLicensePlate_selectedMake: state.vehicleReducer.searchByLicensePlate_selectedMake,
    searchByLicensePlate_selectedModel: state.vehicleReducer.searchByLicensePlate_selectedModel,
    searchByLicensePlate_selectedVin: state.vehicleReducer.searchByLicensePlate_selectedVin,
    searchByLicensePlate_selectedYear: state.vehicleReducer.searchByLicensePlate_selectedYear,
    searchByLicensePlateEnteredValue: state.vehicleReducer.searchByLicensePlateEnteredValue,
    searchByLicensePlateRespReceived: state.vehicleReducer.searchByLicensePlateRespReceived,
    searchByLicensePlateSelectedState: state.vehicleReducer.searchByLicensePlateSelectedState,
    searchByLicensePlateView: state.vehicleReducer.searchByLicensePlateView,
    selectedDate: state.appointmentReducer.selectedDate,
    selectedDealer: state.locReducer.selectedDealer,
    showCancelButtonsFlag: state.confirmationReducer.showCancelButtonsFlag,
    showSpinner: state.ossAppGlobals.showSpinner,
    ultimateOrigin: state.prefillReducer.ultimateOrigin,
    validFlag: state.appointmentReducer.validFlag,
    vCountry: state.prefillReducer.country,
    vehicleMake: state.vehicleReducer.selectedMake,
    vehicleMiles: state.vehicleReducer.selectedMileage,
    vehicleModel: state.vehicleReducer.selectedModel,
    vehicleYear: state.vehicleReducer.selectedYear,
    vhideContactInfoFlag: state.appointmentReducer.hideContactInfoFlag,
    vLocale: state.prefillReducer.locale,
    vMake: state.prefillReducer.make,
    vMiles: state.prefillReducer.miles,
    vModel: state.prefillReducer.model,
    vPlus: state.vehicleReducer.selectedMotor,
    vPrefill: state.prefillReducer,
    vprevBac: state.ossAppGlobals.bac,
    vPreviousBac: state.ossAppGlobals.previousBac,
    vselectedCount: state.ossAppGlobals.selectedCount,
    vTranslations: state.countryOptionsReducer.translations,
    vUnits: state.prefillReducer.units,
    vVin: state.prefillReducer.vin,
    vYear: state.prefillReducer.year,
    origin: state.prefillReducer.origin,
    vEvCertified: state.prefillReducer.certified,
    loadingFlag: state.appointmentReducer.loadingFlag,
    pickupAddress: state.appointmentReducer.pickupAddress,
    transportation: state.appointmentReducer.transportation,
    isVinEligibleForMobileService: state.mobileServiceReducer.isVinEligibleForMobileService,
});

export default withRouter(
    connect(mapStateToProps, {
        getVehicleMakes,
        getVehicleModels,
        resetPreviousBac,
        setDeleteAppointmentFlag,
        setFlowSource,
        setModifyFlag,
        setNewAppointmentFlag,
        setPrefillHeader,
        setVehicleData,
        setHideContactInfoFlag,
        setValidFlag,
    })(Footer)
);
