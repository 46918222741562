import {
    SET_AVAILABILITY_GUID,
    SET_MOBILE_SERVICE_ADDRESS_AND_PHONE_NUMBER,
    SET_MOBILE_SERVICE_NEXT_PAGE,
    SET_MOBILE_SERVICE_NOT_AVAILABLE,
    SET_MOBILE_SERVICE_NOT_AVAILABLE_FOR_DEALER,
    SET_MOBILE_SERVICE_PLUS_ACKNOWLEDGE_MODEL,
    SET_NO_TIMESLOT_ERROR,
    SET_OPTION_CALL_LOADING,
    SET_PICK_UP_ADDRESS_ERROR,
    SET_VIN_ELIGIBILITY_FOR_MOBILE_PLUS,
    SET_VAN_ERROR,
    SET_STOP_PRESELECT_MOBILE_SERVICE,
    SET_ON_TRAC_ERROR_ON_SUBMISSION,
    SET_DMS_ERROR,
    SET_SERVICE_ADDRESS,
    SET_NEW_SERVICE_LIST, SET_DMS_ERROR_ON_EDIT,
} from './ActionTypes';

export function setAppGlobals(request) {
    return {
        type: 'SET_APP_GLOBALS',
        payload: request,
    };
}

export function setAppointmentDate(data) {
    return {
        type: 'SET_APPT_DATE',
        payload: data,
    };
}

export function setFirstAvailDateEmpty(boolean) {
    return {
        type: 'SET_FIRST_AVAIL_DATE_EMPTY',
        payload: boolean,
    };
}

export function setConciergeAddressIsSame(data) {
    return {
        type: 'SET_CONCIERGE_ADDRESS_IS_SAME',
        payload: data,
    };
}

export function setConciergePhoneNumber(data) {
    return {
        type: 'SET_CONCIERGE_PHONE_NUMBER',
        payload: data,
    };
}

export function setDropoffAddress(data) {
    return {
        type: 'SET_DROPOFF_ADDRESS',
        payload: data,
    };
}

export function showLoginError(showError) {
    return {
        type: 'SHOW_LOGIN_ERROR',
        payload: showError,
    };
}

export function setLoginErrorMessage(message) {
    return {
        type: 'SET_LOGIN_ERROR_MESSAGE',
        payload: message,
    };
}

export function setNetworkErrorFlag(data) {
    return {
        type: 'SET_NETWORK_ERROR_FLG',
        payload: data,
    };
}

export function setPickupAddress(data) {
    return {
        type: 'SET_PICKUP_ADDRESS',
        payload: data,
    };
}

export function setShowTimesFlag(data) {
    return {
        type: 'SET_SHOW_TIMES_FLG',
        payload: data,
    };
}

export function setTimeIndex(data) {
    return {
        type: 'SET_TIME_INDEX',
        payload: data,
    };
}

export function searchVehicleByLicensePlateResp(request) {
    return {
        type: 'SET_VEHICLE_SEARCH_BY_LICENSE_RESP',
        payload: request,
    };
}

export function searchVehicleByLicensePlateRespBackButtonClicked() {
    return {
        type: 'SEARCH_BY_LICENSE_RESP_BACK_BUTTON_CLICKED',
    };
}

export function setVendorErrorMessage(data) {
    return {
        type: 'SET_VENDOR_ERROR_MESSAGE',
        payload: data,
    };
}
export function setOnTracError(data) {
    return {
        type: 'SET_ONTRAC_ERROR',
        payload: data,
    };
}

export function setFlowSource(flowSource) {
    return {
        type: 'SET_FLOW_SOURCE',
        payload: flowSource,
    };
}

export function setServiceGlobals(request) {
    return {
        type: 'SET_SERVICE_GLOBALS',
        payload: request,
    };
}

export function setSelectedService(service) {
    return {
        type: 'SET_SERVICE_SELECT',
        payload: service,
    };
}

export function setServiceLane(request) {
    return {
        type: 'SET_SERVICE_LANE',
        payload: request,
    };
}

export function setCallCount(count) {
    return {
        type: 'SET_CALL_COUNT',
        payload: count,
    };
}

export function setHeaderTitle(location) {
    return {
        type: 'SET_HEADER_FOOTER',
        payload: location,
    };
}

export function setSelectedOffer(offerID) {
    return {
        type: 'SET_SELECTED_OFFER',
        payload: offerID,
    };
}

export function setOilChangeOffer(offerID) {
    return {
        type: 'SET_OIL_CHANGE_OFFER',
        payload: offerID,
    };
}

export function populateSelectedOffer(offerID) {
    return {
        type: 'POPULATE_SELECTED_OFFER',
        payload: offerID,
    };
}

export function populateSelectedServiceOffer(offerID) {
    return {
        type: 'POPULATE_SELECTED_SERVICE_OFFER',
        payload: offerID,
    };
}

export function restoreSelectedServiceOffer(offerID) {
    return {
        type: 'RESTORE_SELECTED_SERVICE_OFFER',
        payload: offerID,
    };
}

export function addMISCService(service) {
    return {
        type: 'ADD_MISC_SERVICE',
        payload: service,
    };
}

export function addTireFinderService(service) {
    return {
        type: 'ADD_TIRE_FINDER_SERVICE',
        payload: service,
    };
}

export function addTireFinderServiceString(service) {
    return {
        type: 'ADD_TIRE_FINDER_SERVICE_STRING',
        payload: service,
    };
}

export function setDisplayOffersServices(itemsToView) {
    return {
        type: 'DISPLAY_OFFERS_SERVICES',
        payload: itemsToView,
    };
}

export function setOptInFlag(optInFlag) {
    return {
        type: 'SET_OPT_IN_FLAG',
        payload: optInFlag,
    };
}

export function setBusinessName(businessName) {
    return {
        type: 'SET_BUSINESS_NAME',
        payload: businessName,
    };
}

export function setBusinessDNI(businessDNI) {
    return {
        type: 'SET_BUSINESS_DNI',
        payload: businessDNI,
    };
}

export function setDNI(dni) {
    return {
        type: 'SET_DNI',
        payload: dni,
    };
}

export function setIsBusinessCustomer(isBusinessCustomer) {
    return {
        type: 'SET_IS_BUSINESS_CUSTOMER',
        payload: isBusinessCustomer,
    };
}

export function setCustomerEmail(temporaryEmail) {
    return {
        type: 'SET_CUSTOMER_EMAIL',
        payload: temporaryEmail,
    };
}

export function setMarketingOptOutFlag(marketingOptOutFlag) {
    return {
        type: 'SET_MARKETING_OPT_OUT_FLAG',
        payload: marketingOptOutFlag,
    };
}

export function setPrivacyOptInFlag(privacyOptInFlag) {
    return {
        type: 'SET_PRIVACY_OPT_IN_FLAG',
        payload: privacyOptInFlag,
    };
}

export function setCustomerFirstName(customerFirstName) {
    const trimFirstName = customerFirstName.replace('.', ' ');
    return {
        type: 'SET_CUSTOMER_FIRSTNAME',
        payload: trimFirstName,
    };
}

export function setCustomerLastName(customerLastName) {
    const trimLastName = customerLastName.replace('.', ' ');
    return {
        type: 'SET_CUSTOMER_LASTNAME',
        payload: trimLastName,
    };
}

export function setCustomerPhoneNumber(temporaryPhoneNumber) {
    return {
        type: 'SET_CUSTOMER_PHONE_NUMBER',
        payload: temporaryPhoneNumber,
    };
}

export function setFirstName(firstname) {
    return {
        type: 'SET_FIRST_NAME',
        payload: firstname,
    };
}

export function setLastName(lastname) {
    return {
        type: 'SET_LAST_NAME',
        payload: lastname,
    };
}

export function setCity(city) {
    return {
        type: 'SET_CITY',
        payload: city,
    };
}

export function setPostalCode(zipCode) {
    return {
        type: 'SET_POSTAL_CODE',
        payload: zipCode,
    };
}

export function setRegionCode(state) {
    return {
        type: 'SET_REGION_CODE',
        payload: state,
    };
}

export function setEmail(email) {
    return {
        type: 'SET_EMAIL',
        payload: email,
    };
}

export function setStreet(street) {
    return {
        type: 'SET_STREET',
        payload: street,
    };
}

export function setChooseOneServiceOffer(itemsToView) {
    return {
        type: 'CHOOSE_ONE_SERVICE_OFFER',
        payload: itemsToView,
    };
}

export function setAdvisor(advisor) {
    return {
        type: 'SET_ADVISOR',
        payload: advisor,
    };
}

export function setAdvisors(advisors) {
    return {
        type: 'SET_ADVISORS',
        payload: advisors,
    };
}

export function setTransportation(transportation) {
    return {
        type: 'SET_TRANSPORTATION',
        payload: transportation,
    };
}

export function setTransportationOptions(transportationOptions) {
    return {
        type: 'SET_TRANSPORTATION_OPTIONS',
        payload: transportationOptions,
    };
}

export function setTransportationCallComplete(transportationComplete) {
    return {
        type: 'SET_TRANSPORTATION_CALL_COMPLETE',
        payload: transportationComplete,
    };
}

export function addAdditionalComments(comments) {
    return {
        type: 'SET_COMMENTS',
        payload: comments,
    };
}

export function reRunPEGA(request) {
    return {
        type: 'RERUN_PEGA',
        payload: request,
    };
}

export function setPrefill(params) {
    return {
        type: 'SET_PREFILL',
        payload: params,
    };
}

export function setBrandsiteOrigin(flag) {
    return {
        type: 'SET_BRANDSITE_ORIGIN',
        payload: flag,
    };
}

export function setActComplete(value) {
    return {
        type: 'SET_ACT_COMP',
        payload: value,
    };
}

export function setAppConfig(config) {
    return {
        type: 'SET_APPCONFIG',
        payload: config,
    };
}

export function setBranchDealer(flag) {
    return {
        type: 'SET_BRANCHDEALER',
        payload: flag,
    };
}

export function setPrefillMocked(params_mocked) {
    return {
        type: 'SET_PREFILL_MOCKED',
        payload: params_mocked,
    };
}

export function setIsBannerDisplayed(flag) {
    return {
        type: 'IS_BANNER_DISPLAYED',
        payload: flag,
    };
}

export function setPrefillHeader(params_mocked) {
    return {
        type: 'SET_PREFILL_HEADER',
        payload: params_mocked,
    };
}

export function setRewardsPoints(points) {
    return {
        type: 'SET_REWARDS_POINTS',
        payload: points,
    };
}

export function setMockedTranslations(translations) {
    return {
        type: 'SET_MOCKED_TRANSLATIONS',
        payload: translations,
    };
}

export function setModifyFlag(modifyFlag) {
    return {
        type: 'SET_MODIFY_FLAG',
        payload: modifyFlag,
    };
}

export function setDeleteAppointmentFlag(deleteAppointmentFlag) {
    return {
        type: 'SET_DELETE_APPOINTMENT_FLAG',
        payload: deleteAppointmentFlag,
    };
}

export function setSpinner(val) {
    return {
        type: 'SET_SPINNER',
        payload: val,
    };
}

export function setRecordLocator(val) {
    return {
        type: 'SET_RECORD_LOCATOR',
        payload: val,
    };
}

export function setTranslations(translation) {
    return {
        type: 'SET_TRANSLATIONS',
        payload: translation,
    };
}
export function setLocale(locale) {
    return {
        type: 'SET_LOCALE',
        payload: locale,
    };
}

export function setDealerSummary(dealersummary) {
    return {
        type: 'SET_DEALERSUMMARY',
        payload: dealersummary,
    };
}

export function setDealer(dealer) {
    return {
        type: 'SELECTED_DEALER',
        payload: dealer,
    };
}

export function setCountryOptions(countryOptions, flow) {
    return {
        type: 'SET_COUNTRY_OPTIONS',
        payload: countryOptions,
        flow,
    };
}

export function setValidFlag(validFlag) {
    return {
        type: 'SET_VALID_FLAG',
        payload: validFlag,
    };
}

export function setPrefillMileage(mileage) {
    return {
        type: 'SET_PREFILL_MILEAGE',
        payload: mileage,
    };
}

export function resetPreviousBac(bac) {
    return {
        type: 'RESET_PREVIOUS_BAC',
        payload: bac,
    };
}

export function setShowCancelButtonsFlag(requestCallFlag) {
    return {
        type: 'SET_SHOW_CANCEL_BUTTONS_FLAG',
        payload: requestCallFlag,
    };
}

export function setPrefillEdit(data) {
    return {
        type: 'SET_PREFILLEDIT',
        payload: data,
    };
}
export function setOssGlobalEdit(data) {
    return {
        type: 'SET_OSSGLOBALEDIT',
        payload: data,
    };
}

export function setDateFromEditAppointment(data) {
    return {
        type: 'SET_DATE_EDIT',
        payload: data,
    };
}

export function setPrefFromEdit(data) {
    return {
        type: 'SET_COMM_PREF',
        payload: data,
    };
}

export function setVehicleYears(years) {
    return {
        type: 'SET_VEHICLE_YEARS',
        payload: years,
    };
}

export function setVehicleMakes(makes) {
    return {
        type: 'SET_VEHICLE_MAKES',
        payload: makes,
    };
}

export function setVehicleModels(models) {
    return {
        type: 'SET_VEHICLE_MODELS',
        payload: models,
    };
}

export function setVehiclePlus(plus) {
    return {
        type: 'SET_VEHICLE_PLUS',
        payload: plus,
    };
}

export function setVehicleData(data) {
    return {
        type: 'SET_VEHICLE_DATA',
        payload: data,
    };
}

export function setHideContactInfoFlag(hideContactInfoFlag) {
    return {
        type: 'SET_HIDE_CONTACT_INFO_FLAG',
        payload: hideContactInfoFlag,
    };
}

export function setFromReviewFlag(fromReviewFlag) {
    return {
        type: 'SET_FROM_REVIEW_FLAG',
        payload: fromReviewFlag,
    };
}

export function setIsValidated(isValidated) {
    return {
        type: 'SET_IS_VALIDATED',
        payload: isValidated,
    };
}

export function setLoadingFlag(loadingFlag) {
    return {
        type: 'SET_LOADING_FLAG',
        payload: loadingFlag,
    };
}

export function setVehicleImage(image) {
    return {
        type: 'SET_IMAGE',
        payload: image,
    };
}

export function setVehicleImage_searchByLicensePlate1(image) {
    return {
        type: 'SET_IMAGE_SEARCH_BY_LICENSE_IMAGE1',
        payload: image,
    };
}

export function setVehicleImage_searchByLicensePlate2(image) {
    return {
        type: 'SET_IMAGE_SEARCH_BY_LICENSE_IMAGE2',
        payload: image,
    };
}

export function showLocationIcon(locationIcon) {
    return {
        type: 'SHOW_LOCATION_ICON',
        payload: locationIcon,
    };
}

export function hideUseLocationButton(useLocationButton) {
    return {
        type: 'HIDE_USE_LOCATION_BUTTON',
        payload: useLocationButton,
    };
}

export function showListAndMap(listAndMap) {
    return {
        type: 'SHOW_LIST_AND_MAP',
        payload: listAndMap,
    };
}

export function showLocationIconFalse() {
    return {
        type: 'SHOW_LOCATION_ICON_FALSE',
    };
}

export function hideUseLocationButtonFalse() {
    return {
        type: 'HIDE_USE_LOCATION_BUTTON_FALSE',
    };
}

export function showListAndMapFalse() {
    return {
        type: 'SHOW_LIST_AND_MAP_FALSE',
    };
}

export function setConciergeComments(comments) {
    return {
        type: 'SET_CONCIERGE_COMMENTS',
        payload: comments,
    };
}

export function setHideDistanceTrue() {
    return {
        type: 'HIDE_DISTANCE_TRUE',
    };
}

export function setHideDistanceFalse() {
    return {
        type: 'HIDE_DISTANCE_FALSE',
    };
}

export function clearSelectedDealer() {
    return {
        type: 'CLEAR_SELECTED_DEALER',
    };
}

export function disableAppointmentObj() {
    return {
        type: 'DISABLE_APPT_OBJ',
    };
}

export function setAvailFlagFalse() {
    return {
        type: 'SET_AVAIL_FLAG_FALSE',
    };
}

export function setAvailErrorFlag() {
    return {
        type: 'SET_AVAIL_ERROR_FLG',
    };
}

export function disableShowMore(nameSearch) {
    return {
        type: 'DISABLE_SHOW_MORE',
        payload: nameSearch,
    };
}

export function setRenderFlagFalse() {
    return {
        type: 'SET_RENDER_FLAG_FALSE',
    };
}

export function setRenderFlagTrue() {
    return {
        type: 'SET_RENDER_FLAG_TRUE',
    };
}

export function setSearchFlag() {
    return {
        type: 'SET_SEARCH_FLAG',
    };
}

export function showSearchBoxErrorTrue() {
    return {
        type: 'SHOW_SEARCH_BOX_ERROR_TRUE',
    };
}

export function showSearchBoxErrorFalse() {
    return {
        type: 'SHOW_SEARCH_BOX_ERROR_FALSE',
    };
}

export function setNewAppointmentFlag(newAppointmentFlag) {
    return {
        type: 'SET_NEW_APPOINTMENT_FLAG',
        payload: newAppointmentFlag,
    };
}

export function setNetworkErrorFlgServices(networkErrorFlag) {
    return {
        type: 'SET_SERVICES_ERROR_FLAG',
        payload: networkErrorFlag,
    };
}

export function setExpandedOffer(offer) {
    return {
        type: 'SET_EXPANDED_OFFER',
        payload: offer,
    };
}

export function setBusinessNameFlag(flag) {
    return {
        type: 'SET_BUSINESS_NAME_FLAG',
        payload: flag,
    };
}

export function setShowAuthNameFlag(flag) {
    return {
        type: 'SET_SHOW_AUTH_NAME_FLAG',
        payload: flag,
    };
}

export function setEditFlagAnalytics(flag) {
    return {
        type: 'SET_EDIT_FLAG_ANALYTICS',
        payload: flag,
    };
}

export function rewardsRan(headerNav) {
    return {
        type: 'REWARDS_RAN',
        payload: headerNav,
    };
}

export function setLoyaltyDetails(loyaltyDetails) {
    return {
        type: 'SET_LOYALTY_DETAILS',
        payload: loyaltyDetails,
    };
}

export function useLoyaltyPoints(flag) {
    return {
        type: 'USE_LOYALTY_POINTS',
        payload: flag,
    };
}

export function softEnroll(flag) {
    return {
        type: 'SOFT_ENROLL',
        payload: flag,
    };
}

export function getLoyaltyError(flag) {
    return {
        type: 'GET_LOYALTY_ERROR',
        payload: flag,
    };
}

export function enrollMyRewardsError(flag) {
    return {
        type: 'ENROLL_MY_REWARDS_ERROR',
        payload: flag,
    };
}

export function enrollMyRewardsFlag(flag) {
    return {
        type: 'ENROLL_MY_REWARDS_FLAG',
        payload: flag,
    };
}

export function showEnrollSpinner(flag) {
    return {
        type: 'SHOW_ENROLL_SPINNER',
        payload: flag,
    };
}
export function learnMoreMyRewards(flag) {
    return {
        type: 'LEARN_MORE_MY_REWARDS',
        payload: flag,
    };
}

export function showError(flag) {
    return {
        type: 'SHOW_ERROR',
        payload: flag,
    };
}

export function setStartDate(flag) {
    return {
        type: 'SET_START_DATE',
        payload: flag,
    };
}

export function setSelectedPlace(place) {
    return {
        type: 'SET_SELECTED_PLACE',
        payload: place,
    };
}

export function setMyRewardsPointsIndicator(flag) {
    return {
        type: 'SET_MY_REWARDS_POINTS_INDICATOR',
        payload: flag,
    };
}

export function setGarageData(myGarage) {
    return {
        type: 'SET_GARAGE_DATA',
        payload: myGarage,
    };
}

export function setGarageError(error) {
    return {
        type: 'SET_GARAGE_ERROR',
        payload: error,
    };
}

export function setAttemptedLogin(attemptedLogin) {
    return {
        type: 'SET_ATTEMPTED_LOGIN',
        payload: attemptedLogin,
    };
}

export function resetGarageError() {
    return {
        type: 'RESET_GARAGE_ERROR',
    };
}

export function setGarageLoading(flag) {
    return {
        type: 'SET_GARAGE_LOADING',
        payload: flag,
    };
}

export function setSessionActive(flag) {
    return {
        type: 'SET_SESSION_ACTIVE',
        payload: flag,
    };
}

export function checkSession(flag) {
    return {
        type: 'CHECK_SESSION',
        payload: flag,
    };
}

export function setGarageIndex(index) {
    return {
        type: 'SET_GARAGE_INDEX',
        payload: index,
    };
}

export function setGarageVin(vin) {
    return {
        type: 'SET_MY_GARAGE_VIN',
        payload: vin,
    };
}

export function setVehicleSelected(flag) {
    return {
        type: 'SET_VEHICLE_SELECTED',
        payload: flag,
    };
}

export function setMyGarageError(flag) {
    return {
        type: 'SET_MY_GARAGE_ERROR',
        payload: flag,
    };
}

export function setMyAccountLogin(flag) {
    return {
        type: 'SET_MY_ACCOUNT_LOGIN',
        payload: flag,
    };
}

export function setProfileId(gpmsProfileId) {
    return {
        type: 'SET_PROFILE_ID',
        payload: gpmsProfileId,
    };
}

export function setEvCertified(certified) {
    return {
        type: 'SET_EV_CERTIFIED',
        payload: certified,
    };
}

export function setStarRating(rating) {
    return {
        type: 'SET_STAR_RATING',
        payload: rating,
    };
}

export function setFeedbackReason(reason) {
    return {
        type: 'SET_FEEDBACK_REASON',
        payload: reason,
    };
}

export function setCancelError(val) {
    return {
        type: 'SET_CANCEL_ERROR',
        payload: val,
    };
}

export function setSession(session) {
    return {
        type: 'SET_SESSION',
        payload: session,
    };
}

export function setVinEligibility(payload) {
    return {
        type: SET_VIN_ELIGIBILITY_FOR_MOBILE_PLUS,
        payload: payload,
    };
}

export function setDealerEligibilityForMobilePlus(payload) {
    return {
        type: SET_VIN_ELIGIBILITY_FOR_MOBILE_PLUS,
        payload: payload,
    };
}
export function stopPreselectMobileService(payload) {
    return {
        type: SET_STOP_PRESELECT_MOBILE_SERVICE,
        payload: payload,
    };
}

export function setMobileServicePlusAcknowledgeModel(payload) {
    return {
        type: SET_MOBILE_SERVICE_PLUS_ACKNOWLEDGE_MODEL,
        payload: payload,
    };
}

export function setMobileServiceAddressAndPhoneNumber(payload) {
    return {
        type: SET_MOBILE_SERVICE_ADDRESS_AND_PHONE_NUMBER,
        payload: {
            serviceAddresses: payload.serviceAddresses,
            serviceCommunications: payload.serviceCommunications,
        },
    };
}

export function setAvailabilityGuid(payload) {
    return {
        type: SET_AVAILABILITY_GUID,
        payload: payload,
    };
}

export function setNoTimeSlotAvailableError(payload) {
    return {
        type: SET_NO_TIMESLOT_ERROR,
        payload: payload,
    };
}

export function setMobileServiceNotAvailable(payload) {
    return {
        type: SET_MOBILE_SERVICE_NOT_AVAILABLE,
        payload: payload,
    };
}

export function setMobileServiceNotAvailableForDealer(payload) {
    return {
        type: SET_MOBILE_SERVICE_NOT_AVAILABLE_FOR_DEALER,
        payload: payload,
    };
}

export function setMobileServiceNextPage(payload) {
    return {
        type: SET_MOBILE_SERVICE_NEXT_PAGE,
        payload: payload,
    };
}

export function setPickUpAddressError(payload) {
    return {
        type: SET_PICK_UP_ADDRESS_ERROR,
        payload: payload,
    };
}

export function setVanError(payload) {
    return {
        type: SET_VAN_ERROR,
        payload: payload,
    };
}
export function setOnTracErrorOnSubmission(payload) {
    return {
        type: SET_ON_TRAC_ERROR_ON_SUBMISSION,
        payload: {
            showOnTracError: payload.showOnTracError,
            onTracTries: payload.onTracTries,
        },
    };
}

export function setDmsError(payload) {
    return {
        type: SET_DMS_ERROR,
        payload: payload,
    };
}

export function setDmsErrorOnEdit(payload) {
    return {
        type: SET_DMS_ERROR_ON_EDIT,
        payload: payload,
    };
}

export function setOptionCallLoading(payload) {
    return {
        type: SET_OPTION_CALL_LOADING,
        payload: payload,
    };
}

export function setServiceAddress(payload) {
    return {
        type: SET_SERVICE_ADDRESS,
        payload: {
            pickupAddress: payload.pickupAddress,
            dropoffAddress: payload.dropoffAddress,
            serviceAddresses: payload.serviceAddresses,
            serviceCommunications: payload.serviceCommunications,
        },
    };
}

export function setNewServiceList(payload) {
    return {
        type: SET_NEW_SERVICE_LIST,
        payload: payload,
    };
}
