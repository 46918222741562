import {
    SET_ADVISOR,
    SET_ADVISORS,
    SET_APPT_DATE,
    SET_AVAIL_ERROR_FLG,
    SET_BUSINESS_DNI,
    SET_BUSINESS_NAME_FLAG,
    SET_BUSINESS_NAME,
    SET_CONCIERGE_ADDRESS_IS_SAME,
    SET_CONCIERGE_COMMENTS,
    SET_CONCIERGE_PHONE_NUMBER,
    SET_CUSTOMER_EMAIL,
    SET_CUSTOMER_FIRSTNAME,
    SET_CUSTOMER_LASTNAME,
    SET_CUSTOMER_PHONE_NUMBER,
    SET_DATE_EDIT,
    SET_DATES,
    SET_DNI,
    SET_DROPOFF_ADDRESS,
    SET_FIRST_AVAIL_DATE,
    SET_FIRST_AVAIL_DATE_EMPTY,
    SET_FROM_REVIEW_FLAG,
    SET_HIDE_CONTACT_INFO_FLAG,
    SET_IS_BUSINESS_CUSTOMER,
    SET_IS_VALIDATED,
    SET_LOADING_FLAG,
    SET_MARKETING_OPT_OUT_FLAG,
    SET_MODIFY_FLAG,
    SET_NETWORK_ERROR_FLG,
    SET_OPT_IN_FLAG,
    SET_PICKUP_ADDRESS,
    SET_PREV_APPT_OBJ,
    SET_PRIVACY_OPT_IN_FLAG,
    SET_SERVICE_LANE,
    SET_SHOW_AUTH_NAME_FLAG,
    SET_SHOW_TIMES_FLG,
    SET_START_DATE,
    SET_TIME_INDEX,
    SET_TRANSPORTATION_OPTIONS,
    SET_TRANSPORTATION,
    SET_TRANSPORTATION_CALL_COMPLETE,
    SET_VALID_FLAG,
    SET_VENDOR_ERROR_MESSAGE,
    SET_ONTRAC_ERROR,
    SET_AVAILABILITY_GUID,
    SET_NO_TIMESLOT_ERROR,
    SET_PICK_UP_ADDRESS_ERROR,
    SET_OPTION_CALL_LOADING,
    SET_VAN_ERROR,
    SET_ON_TRAC_ERROR_ON_SUBMISSION,
    SET_DMS_ERROR,
    SET_DMS_ERROR_ON_EDIT,
    SET_SERVICE_ADDRESS
} from './ActionTypes';

const dayjs = require('dayjs');

const date = dayjs();
const defaultAdvisorObject = {
    name: 'No Preference',
    firstName: null,
    lastName: null,
    id: 0,
    mediaId: 0,
    hideLastName: false,
};

const initialState = {
    advisor: defaultAdvisorObject,
    advisors: null,
    authNameFlag: false,
    availabilityErrorFlag: false,
    availableDates: [],
    businessDni: null,
    businessName: null,
    businessNameFlag: false,
    conciergeAddressIsSame: true,
    conciergeComments: '',
    conciergePhoneNumber: '',
    customerEmail: '',
    customerFirstName: '',
    customerLastName: '',
    customerPhoneNumber: '',
    defaultAdvisorObject,
    dni: null,
    dropoffAddress: '',
    firstAvailDate: null,
    fromReviewFlag: false,
    hideContactInfoFlag: false,
    isBusinessCustomer: false,
    isValidated: false,
    loadingFlag: false,
    marketingOptOutFlag: false,
    modifyFlag: false,
    networkErrorFlag: false,
    numDaysToDisplay: 5,
    oldSelectedDate: null,
    optInFlag: false,
    pickupAddress: '',
    prevAppointmentObject: null,
    privacyOptInFlag: false,
    remainingCharacters: 150,
    selectedDate: null,
    serviceLane: null,
    showTimes: false,
    startDate: date,
    timeIndex: 0,
    transportation: '',
    transportationOptions: [],
    transportationCallComplete: false,
    validFlag: true,
    prevSave: false,
    vendorError: null,
    onTracError: false,
    dmsError: false,
    dmsErrorOnEdit: false,
    availabilityGuid:'',
    showTimeSlotErrorModel:false,
    showPickUpAddressError:false,
    optionCallLoading:false,
    onTracTries:'',
    showOnTracError:false,
    serviceAddresses:null,
    serviceCommunications:null
};

const appointmentReducer = (state = initialState, action) => {
    if (action.payload) {
        switch (action.type) {
            case SET_ADVISOR: {
                return { ...state, advisor: action.payload };
            }
            case SET_ADVISORS: {
                return { ...state, advisors: action.payload };
            }
            case SET_AVAIL_ERROR_FLG: {
                return { ...state, availabilityErrorFlag: getFlag(action.payload) };
            }
            case SET_TRANSPORTATION: {
                return { ...state, transportation: action.payload !== 'false' ? action.payload : '',showPickUpAddressError: false };
            }
            case SET_TRANSPORTATION_OPTIONS: {
                return { ...state, transportationOptions: action.payload };
            }
            case SET_TRANSPORTATION_CALL_COMPLETE: {
                return { ...state, transportationCallComplete: true };
            }
            case SET_OPT_IN_FLAG: {
                return { ...state, optInFlag: getFlag(action.payload) };
            }
            case SET_PRIVACY_OPT_IN_FLAG: {
                return { ...state, privacyOptInFlag: getFlag(action.payload) };
            }
            case SET_MARKETING_OPT_OUT_FLAG: {
                return { ...state, marketingOptOutFlag: getFlag(action.payload) };
            }
            case SET_CONCIERGE_PHONE_NUMBER: {
                return { ...state, conciergePhoneNumber: action.payload !== 'null' ? action.payload : '' };
            }
            case SET_CUSTOMER_PHONE_NUMBER: {
                return { ...state, customerPhoneNumber: action.payload !== 'null' ? action.payload : '' };
            }
            case SET_CUSTOMER_EMAIL: {
                return { ...state, customerEmail: action.payload };
            }
            case SET_CUSTOMER_FIRSTNAME: {
                return { ...state, customerFirstName: action.payload };
            }
            case SET_CUSTOMER_LASTNAME: {
                return { ...state, customerLastName: action.payload };
            }
            case SET_CONCIERGE_COMMENTS: {
                return {
                    ...state,
                    conciergeComments: action.payload,
                    remainingCharacters: 150 - action.payload.length,
                };
            }
            case SET_IS_BUSINESS_CUSTOMER: {
                return { ...state, isBusinessCustomer: getFlag(action.payload) };
            }
            case SET_BUSINESS_NAME: {
                return { ...state, businessName: action.payload };
            }
            case SET_BUSINESS_DNI: {
                return { ...state, businessDni: action.payload };
            }
            case SET_DNI: {
                return { ...state, dni: action.payload !== 'empty' ? action.payload : '' };
            }
            case SET_IS_VALIDATED: {
                return { ...state, isValidated: getFlag(action.payload) };
            }
            case SET_LOADING_FLAG: {
                return { ...state, loadingFlag: getFlag(action.payload) };
            }
            case SET_APPT_DATE: {
                return { ...state, selectedDate: action.payload !== 'null' ? action.payload : null };
            }
            case SET_CONCIERGE_ADDRESS_IS_SAME: {
                return { ...state, conciergeAddressIsSame: getFlag(action.payload) };
            }
            case SET_DATES: {
                return { ...state, availableDates: action.payload };
            }
            case SET_DROPOFF_ADDRESS: {
                return { ...state, dropoffAddress: action.payload };
            }
            case SET_SHOW_TIMES_FLG: {
                return { ...state, showTimes: getFlag(action.payload) };
            }
            case SET_FIRST_AVAIL_DATE: {
                return { ...state, firstAvailDate: action.payload };
            }
            //Will have to go back and make sure this works
            case SET_FIRST_AVAIL_DATE_EMPTY: {
                return { ...state, firstAvailDate: null };
            }
            case SET_MODIFY_FLAG: {
                return { ...state, modifyFlag: getFlag(action.payload) };
            }
            case SET_NETWORK_ERROR_FLG: {
                return { ...state, networkErrorFlag: getFlag(action.payload) };
            }
            case SET_START_DATE: {
                return { ...state, startDate: action.payload };
            }
            case SET_PICKUP_ADDRESS: {
                return { ...state, pickupAddress: action.payload === 'empty'? '' : action.payload,showPickUpAddressError: false };
            }
            case SET_PREV_APPT_OBJ: {
                return { ...state, prevAppointmentObject: action.payload };
            }
            case SET_SERVICE_LANE: {
                return { ...state, serviceLane: action.payload };
            }
            case SET_SHOW_AUTH_NAME_FLAG: {
                return { ...state, authNameFlag: action.payload === 'true' };
            }
            case SET_TIME_INDEX: {
                return { ...state, timeIndex: action.payload === 1 ? 0 : action.payload };
            }
            case SET_VALID_FLAG: {
                return { ...state, validFlag: getFlag(action.payload) };
            }
            case SET_DATE_EDIT: {
                return {
                    ...state,
                    startDate: action.payload,
                    selectedDate: action.payload,
                    oldSelectedDate: action.payload,
                };
            }
            case SET_HIDE_CONTACT_INFO_FLAG: {
                return { ...state, hideContactInfoFlag: getFlag(action.payload) };
            }
            case SET_FROM_REVIEW_FLAG: {
                return { ...state, fromReviewFlag: getFlag(action.payload) };
            }
            case SET_BUSINESS_NAME_FLAG: {
                return { ...state, businessNameFlag: getFlag(action.payload) };
            }
            case SET_VENDOR_ERROR_MESSAGE: {
                return { ...state, vendorError: action.payload };
            }
            case SET_ONTRAC_ERROR: {
                return { ...state, onTracError: getFlag(action.payload) };
            }
            case SET_AVAILABILITY_GUID:{
                return {...state,availabilityGuid: action.payload}
            }
            case SET_NO_TIMESLOT_ERROR:{
                return {...state,showTimeSlotErrorModel: action.payload === 'true'}
            }

            case SET_PICK_UP_ADDRESS_ERROR : {
                return {...state,showPickUpAddressError: action.payload === 'true'}
            }
            case SET_OPTION_CALL_LOADING : {
                return {...state,optionCallLoading: action.payload === 'true'}
            }
            case SET_VAN_ERROR : {
                return {...state,showVanError: action.payload === 'true'}
            }
            case SET_DMS_ERROR : {
                return { ...state, dmsError: action.payload === 'true'};
            }
            case SET_DMS_ERROR_ON_EDIT : {
                return { ...state, dmsErrorOnEdit: action.payload === 'true'};
            }
            case SET_ON_TRAC_ERROR_ON_SUBMISSION : {
                return {...state,onTracTries: action.payload.onTracTries,showOnTracError: action.payload.showOnTracError}
            }
            case SET_SERVICE_ADDRESS: {
                return { ...state,
                    pickupAddress: action.payload.pickupAddress ? action.payload.pickupAddress:state.pickupAddress,
                    dropoffAddress:action.payload.dropoffAddress ? action.payload.dropoffAddress:state.dropoffAddress,
                    serviceAddresses:action.payload.serviceAddresses,
                    serviceCommunications:action.payload.serviceCommunications};
            }
            default:
                return state;
        }
    } else {
        return state;
    }
};

const setDateFromEdit = (state, action) => {
    if (action.payload) {
        state.startDate = action.payload;
        state.selectedDate = action.payload;
        state.oldSelectedDate = action.payload;
    }
};

export const setConciergeComments = (state, action) => {
    state.conciergeComments = action.payload;
    state.remainingCharacters = 150 - action.payload.length;

    return state;
};
export function getFlag(payload) {
    return !(payload === false || payload === 'false');
}

export default appointmentReducer;
