import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import UnsubmittedAppointmentDateTime from "./UnsubmittedAppointmentDateTime";
import UnsubmittedVehicleInfo from "./UnsubmittedVehicleInfo";
import SelectedServices from "../Review/SelectedServices";
import React from "react";
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import {getPhoneNum} from "../Appointment/Dealership/DealershipPhone";


class DmsErrorPage extends React.Component {

    render() {
        const serviceDeptPhoneNumber = this.props.dealershipSummary?.departments.filter((item)=>item.name==='SERVICE')[0].phoneNumber;
        const formatPhoneNUmber = (input)=>{
            input = input.replace(/\D/g,'');
            let size = input.length;
            if (size>0) {input="("+input}
            if (size>3) {input=input.slice(0,4)+") "+input.slice(4,11)}
            if (size>6) {input=input.slice(0,9)+"-" +input.slice(9)}
            return input
        };

        let errorMessage = this.props.vTranslations.APPT_NOT_SUBMITTED || "APPT_NOT_SUBMITTED";
        if (this.props.cancelError) {
            errorMessage = this.props.vTranslations.APPT_NOT_CANCELLED || "APPT_NOT_CANCELLED";
        } else if (this.props.editError) {
            errorMessage = this.props.vTranslations.APPT_EDIT_FAILURE || "APPT_EDIT_FAILURE";
        }

        const mobilePhoneLink= (<a
            href={`tel:${getPhoneNum(this.props.dealershipSummary, this.props.vCountry)}`}
            target="_blank">
            {`${getPhoneNum(this.props.dealershipSummary, this.props.vCountry)}`}
        </a>);

        let contactDealerMessage = this.props.vTranslations.DMS_ERROR_CONTACT_DEALER || "DMS_ERROR_CONTACT_DEALER";
        contactDealerMessage = contactDealerMessage.replace("{{dealership}}", this.props.dealershipSummary.name);
        contactDealerMessage = contactDealerMessage.replace("{{number}}", formatPhoneNUmber(serviceDeptPhoneNumber));

        let partialContactDealerMessage = this.props.vTranslations.DMS_ERROR_CONTACT_DEALER || "DMS_ERROR_CONTACT_DEALER";
        partialContactDealerMessage = partialContactDealerMessage.replace("{{dealership}}", this.props.dealershipSummary.name);
        partialContactDealerMessage = partialContactDealerMessage.replace("{{number}}", "");

        return <div className="error-page-container">
            <div className="error-message-header bold">
                <div className="error-icon icon alert-caution-red"/>
                <div className="error-message-sorry headline3">
                    {this.props.vTranslations.DMS_ERROR_DISP || "DMS_ERROR_DISP"}
                </div>
            </div>
            <div className="error-message-appt-failure">
                {errorMessage}
            </div>

            <p className="error-message-contact-dealer hide-on-mobile">
                {contactDealerMessage}
            </p>
            <p className="error-message-contact-dealer-mobile hide-on-desktop">
                {partialContactDealerMessage} {mobilePhoneLink}
            </p>
            <Accordion
                square
                defaultExpanded = 'true'
            >
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                >
                    <div className="bold">{this.props.vTranslations.VIEW_UNSUBMITTED_DETAILS || "VIEW_UNSUBMITTED_DETAILS"}</div>
                </AccordionSummary>
                <AccordionDetails class="accordion-details">
                    <div className="dms-error-container">
                        <div className="review-unsubmitted-grid">
                            <UnsubmittedAppointmentDateTime/>
                            <UnsubmittedVehicleInfo/>
                            <SelectedServices />
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>;

    }

}

const mapStateToProps = (state) => ({
    vTranslations: state.countryOptionsReducer.translations,
    vCountry: state.prefillReducer.country,
    showOnTracError:state.appointmentReducer.showOnTracError && state.appointmentReducer.onTracTries === 'second',
    dealershipSummary: state.prefillReducer.dealerSummaryObject.dealershipSummary,
    cancelError: state.confirmationReducer.cancelError,
    editError: state.appointmentReducer.dmsErrorOnEdit
});

export default connect(mapStateToProps)(DmsErrorPage)
withRouter(DmsErrorPage)