import React from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class UnsubmittedVehicleInfo extends React.Component {

    render() {

        return (<div className="review-unsubmitted-vehicle-info-section paper">
            <div
                className={`review-unsubmitted-vehicle-info-title headline3`}
            >
                {this.props.vTranslations.VEHICLE_INFORMATION ? this.props.vTranslations.VEHICLE_INFORMATION : "Vehicle Information"}
            </div>
            <div className="review-unsubmitted-customer-ymm">
                {this.props.year} {this.props.make} {this.props.model}
            </div>
            {
                this.props.vin ? <div className="review-unsubmitted-customer-vin"> {this.props.vin}</div> : <div></div>
            }

        </div>);
    }


}

const mapStateToProps = (state) => ({
    vTranslations: state.countryOptionsReducer.translations,
    year: state.vehicleReducer.selectedYear ? state.vehicleReducer.selectedYear : state.prefillReducer.year,
    make: state.vehicleReducer.selectedMake ? state.vehicleReducer.selectedMake : state.prefillReducer.make,
    model: state.vehicleReducer.selectedModel ? state.vehicleReducer.selectedModel : state.prefillReducer.model,
    vin: state.prefillReducer.vin,
});

export default connect(mapStateToProps)(UnsubmittedVehicleInfo)
withRouter(UnsubmittedVehicleInfo)