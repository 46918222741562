import React from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {formatDateLong} from "../../shared/Utils/TimeLocalization";

class UnsubmittedAppointmentDateTime extends React.Component {
    render() {
        return (<div className="review-unsubmitted-appointment-date-time-section paper">
            <div
                className={`review-unsubmitted-appointment-date-time bold`}
            >
                {this.props.vTranslations.APPOINTMENT_DATE_AND_TIME ? this.props.vTranslations.APPOINTMENT_DATE_AND_TIME : "Date & Time"}
            </div>
            <div>
                {formatDateLong(this.props.selectedDate)}
            </div>
        </div>);
    }
}

const mapStateToProps = (state) => ({
    vTranslations: state.countryOptionsReducer.translations,
    selectedDate: state.appointmentReducer.selectedDate,
});

export default connect(mapStateToProps)(UnsubmittedAppointmentDateTime)
withRouter(UnsubmittedAppointmentDateTime)